import {
  CreateEdpViewRequest,
  EdpViewRefResponse,
  EdpViewResponse,
  QueryResponse,
  UpdateEdpViewRequest,
} from 'mapistry-shared';
import qs from 'qs';
import http, { apiUrl } from '../httpClient';

export interface FetchProjectViewsParams {
  organizationId: string;
  projectId: string;
}
export type FetchProjectViewsResponse = EdpViewRefResponse[];

export async function fetchProjectViews({
  organizationId,
  projectId,
}: FetchProjectViewsParams): Promise<FetchProjectViewsResponse> {
  const res = await http.get<FetchProjectViewsResponse>(
    `/api/v2/organizations/${organizationId}/projects/${projectId}/edp-views`,
  );
  return res.data;
}

export interface FetchLogViewsParams {
  logId: string;
  organizationId: string;
  projectId: string;
}
export type FetchLogViewsResponse = EdpViewResponse[];

// todo@workflows: maybe get rid of this one and favor fetchProjectViews
export async function fetchLogViews({
  logId,
  organizationId,
  projectId,
}: FetchLogViewsParams): Promise<FetchLogViewsResponse> {
  const res = await http.get<FetchLogViewsResponse>(
    `/api/v2/organizations/${organizationId}/logs/${logId}/projects/${projectId}/views`,
  );
  return res.data;
}

export interface FetchViewParams {
  organizationId: string;
  projectId: string;
  viewId: string;
}

export type FetchViewResponse = EdpViewResponse;

export async function fetchView({
  organizationId,
  projectId,
  viewId,
}: FetchViewParams): Promise<EdpViewResponse> {
  const res = await http.get<EdpViewResponse>(
    `/api/v2/organizations/${organizationId}/projects/${projectId}/edp-views/${viewId}`,
  );
  return res.data;
}

export type CreateViewParams = {
  organizationId: string;
  projectId: string;
  view: CreateEdpViewRequest;
};
export type CreateViewResponse = EdpViewResponse;

export async function createView({
  organizationId,
  projectId,
  view,
}: CreateViewParams): Promise<CreateViewResponse> {
  const res = await http.post<EdpViewResponse>(
    `/api/v2/organizations/${organizationId}/projects/${projectId}/edp-views`,
    view,
  );
  return res.data;
}

export type UpdateViewParams = {
  organizationId: string;
  projectId: string;
  view: UpdateEdpViewRequest;
  viewId: string;
};
export type UpdateViewResponse = EdpViewResponse;

export async function updateView({
  organizationId,
  projectId,
  view,
  viewId,
}: UpdateViewParams): Promise<UpdateViewResponse> {
  const res = await http.put<EdpViewResponse>(
    `/api/v2/organizations/${organizationId}/projects/${projectId}/edp-views/${viewId}`,
    view,
  );
  return res.data;
}

export type DeleteViewRequest = {
  organizationId: string;
  projectId: string;
  viewId: string;
};
export type DeleteViewResponse = string;

export async function deleteView({
  viewId,
  organizationId,
  projectId,
}: DeleteViewRequest) {
  const res = await http.delete<DeleteViewResponse>(
    `/api/v2/organizations/${organizationId}/projects/${projectId}/edp-views/${viewId}`,
  );
  return res.data;
}

export interface FetchViewQueryParams {
  organizationId: string;
  perPage?: number;
  projectId: string;
  requestedPage?: number;
  viewId: string;
}

export type FetchViewQueryResponse = QueryResponse;

export async function fetchViewQuery({
  organizationId,
  perPage,
  projectId,
  requestedPage,
  viewId,
}: FetchViewQueryParams): Promise<FetchViewQueryResponse> {
  const res = await http.get<FetchViewQueryResponse>(
    `/api/v2/organizations/${organizationId}/projects/${projectId}/edp-views/${viewId}/query?${qs.stringify(
      {
        currentPage: requestedPage ?? 0,
        perPage: perPage ?? 50,
      },
    )}`,
  );
  return res.data;
}

export type DownloadViewRequest = {
  logId: string;
  organizationId: string;
  projectId: string;
  viewId: string;
};

// todo@workflows - make downloadViewResults logId independent
export async function downloadViewResults({
  viewId,
  logId,
  organizationId,
  projectId,
}: DownloadViewRequest): Promise<void> {
  window.open(
    `${apiUrl}/api/v2/organizations/${organizationId}/logs/${logId}/projects/${projectId}/views/${viewId}/download`,
  );
}
