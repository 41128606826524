import { endOfMonth, startOfDay } from 'date-fns';
import _get from 'lodash.get';
import _pickBy from 'lodash.pickby';
import {
  IntervalFrequencyEnum,
  IntervalFrequencyFactory,
} from 'mapistry-shared';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import APP from '../../../../config';
import { isNullOrUndefined, localEquivalentOfUTC } from '../../../../utils';
import { CalendarEventType, MenuOptionType } from '../../../propTypes';
import withProvider from '../../../withProvider';
import CalendarCard from './CalendarCard';

class CalendarCardContainer extends Component {
  constructor(props) {
    super(props);
    this.handleEventClick = this.handleEventClick.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handlePrevDateClick = this.handlePrevDateClick.bind(this);
    this.handleNextDateClick = this.handleNextDateClick.bind(this);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    this.state = {
      frequency: null,
      customFrequency: null,
      isInitializing: true,
      isLoading: false,
      currentDate,
    };
  }

  componentDidMount() {
    const { calendarName, fetchEvents, projectId } = this.props;
    const { currentDate } = this.state;
    fetchEvents(projectId, calendarName, currentDate);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { events, menuOptions, tooltipData } = this.props;

    let menuOptionsChanged =
      menuOptions.length !== nextProps.menuOptions.length;
    if (!menuOptionsChanged) {
      menuOptions.forEach((option, index) => {
        if (option.label !== nextProps.menuOptions?.[index]?.label)
          menuOptionsChanged = true;
      });
    }

    return (
      events !== nextProps.events ||
      nextState !== this.state ||
      menuOptionsChanged ||
      tooltipData !== nextProps.tooltipData
    );
  }

  componentDidUpdate(prevProps) {
    const { events } = this.props;
    const { currentDate } = this.state;

    if (prevProps.events !== events) {
      const lowFrequencyEvents = events.filter((e) =>
        IntervalFrequencyFactory.For(e.frequency, e.customFrequency).isLow(),
      );
      const currentEvent = events.find(
        (e) =>
          localEquivalentOfUTC(e.dueDate) >= currentDate &&
          localEquivalentOfUTC(e.startDate) <= currentDate,
      );
      const currentEventFrequency = events.length
        ? _get(currentEvent, 'frequency', IntervalFrequencyEnum.AS_NEEDED)
        : IntervalFrequencyEnum.NEVER;
      const currentEventCustomFrequency = _get(currentEvent, 'customFrequency');
      const currentEventIntervalFrequency = IntervalFrequencyFactory.For(
        currentEventFrequency,
        currentEventCustomFrequency,
      );
      // this only works because we only fetch the current month (or day) of high frequency events
      const isLow =
        lowFrequencyEvents.length && !currentEventIntervalFrequency.isLow();
      const frequency = isLow
        ? lowFrequencyEvents[0].frequency
        : currentEventFrequency;
      const customFrequency = isLow
        ? lowFrequencyEvents[0].customFrequency
        : currentEventCustomFrequency;
      this.setState({
        isInitializing: false,
        isLoading: false,
        frequency,
        customFrequency,
      });
    }
  }

  handleDateChange(selectedDate) {
    const { calendarName, fetchEvents, onDateChange, projectId } = this.props;
    if (onDateChange) {
      onDateChange(selectedDate);
    }
    this.setState(
      { currentDate: localEquivalentOfUTC(selectedDate), isLoading: true },
      () => fetchEvents(projectId, calendarName, selectedDate),
    );
  }

  handleEventClick(clickEvent, events, tooMuchData) {
    const { onEventClick } = this.props;
    const sampleEvent = events[0];
    if (tooMuchData) {
      const eventDate = sampleEvent.dueDate || sampleEvent.startDate;
      eventDate.setHours(0, 0, 0, 0);
      this.handleDateChange(eventDate);
    } else if (onEventClick) {
      onEventClick(events, clickEvent);
    }
  }

  handlePrevDateClick() {
    const { currentInterval } = this.props;
    const newDate = new Date(currentInterval.start);
    newDate.setUTCDate(newDate.getUTCDate() - 1);
    this.handleDateChange(newDate);
  }

  handleNextDateClick() {
    const { currentInterval } = this.props;
    const newDate = new Date(currentInterval.end);
    newDate.setUTCDate(newDate.getUTCDate() + 1);
    this.handleDateChange(newDate);
  }

  groupKey(event) {
    const { frequency, customFrequency } = this.state;
    const eventFrequency = IntervalFrequencyFactory.For(
      event.frequency,
      event.customFrequency,
    );
    const viewFrequency = IntervalFrequencyFactory.For(
      frequency,
      customFrequency,
    );
    const eventDate = event.dueDate || event.startDate;
    if (viewFrequency.isLow() && !eventFrequency.isLow()) {
      return endOfMonth(eventDate);
    }
    if (
      frequency !== IntervalFrequencyEnum.HOUR &&
      event.frequency === IntervalFrequencyEnum.HOUR
    ) {
      return startOfDay(eventDate);
    }
    return eventDate;
  }

  addToPreviousMonthWeeklyGroup(weeklyEvent, weeklyGroups) {
    if (
      weeklyEvent.dueDate &&
      weeklyEvent.startDate.getMonth() !== weeklyEvent.dueDate.getMonth()
    ) {
      const prevGroupKey = endOfMonth(weeklyEvent.startDate).toString();
      if (weeklyGroups[prevGroupKey]) {
        weeklyGroups[prevGroupKey].push(weeklyEvent);
      }
    }
  }

  groupEvents() {
    const { events } = this.props;
    if (isNullOrUndefined(events)) {
      return {};
    }
    const { frequency, customFrequency } = this.state;
    const viewFrequency = IntervalFrequencyFactory.For(
      frequency,
      customFrequency,
    );
    const weeklyGroups = {};
    const groupedEvents = events.reduce((acc, event) => {
      const formattedEvent = {
        ...event,
        startDate: localEquivalentOfUTC(event.startDate),
        dueDate: localEquivalentOfUTC(event.dueDate),
        warningDate: localEquivalentOfUTC(event.warningDate),
        key: uuidv4(),
      };
      const groupKey = this.groupKey(formattedEvent);
      if (acc[groupKey]) {
        acc[groupKey].push(formattedEvent);
      } else {
        acc[groupKey] = [formattedEvent];
      }

      if (
        viewFrequency.isLow() &&
        formattedEvent.frequency === IntervalFrequencyEnum.WEEK
      ) {
        if (!weeklyGroups[groupKey]) {
          weeklyGroups[groupKey] = acc[groupKey];
        }
        this.addToPreviousMonthWeeklyGroup(formattedEvent, weeklyGroups);
      }

      return acc;
    }, {});

    if (viewFrequency.isHigh()) {
      return groupedEvents;
    }

    const weeklyGroupsWithSingleEvent = Object.keys(weeklyGroups).filter(
      (key) => weeklyGroups[key].length === 1,
    );

    return _pickBy(
      groupedEvents,
      (_, key) => !weeklyGroupsWithSingleEvent.includes(key),
    );
  }

  render() {
    const {
      currentInterval,
      defaultView,
      filterView,
      helpTextForEvent,
      tooltipData,
      tooltipTitle,
      menuOptions,
      onEventClick,
      selectedFilterOptions,
      title,
    } = this.props;
    const {
      currentDate,
      frequency,
      customFrequency,
      isInitializing,
      isLoading,
    } = this.state;

    return (
      <CalendarCard
        currentDate={currentDate}
        currentInterval={currentInterval}
        defaultView={defaultView}
        eventGroups={this.groupEvents()}
        filterView={filterView}
        frequency={frequency}
        customFrequency={customFrequency}
        helpTextForEvent={helpTextForEvent}
        tooltipData={tooltipData}
        tooltipTitle={tooltipTitle}
        isEventClickable={!isNullOrUndefined(onEventClick)}
        isInitializing={isInitializing}
        isLoading={isLoading}
        menuOptions={menuOptions}
        onDateChange={this.handleDateChange}
        onEventClick={this.handleEventClick}
        onPrevDateClick={this.handlePrevDateClick}
        onNextDateClick={this.handleNextDateClick}
        selectedFilterOptions={selectedFilterOptions}
        title={title}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { calendar } = state;
  const { calendarName, widgetId } = ownProps;
  const identifier = widgetId || calendarName;
  return {
    events: _get(calendar.events, identifier),
    currentInterval: _get(calendar.currentIntervals, identifier),
    projectId: APP.projectId,
  };
};

CalendarCardContainer.propTypes = {
  calendarName: PropTypes.string.isRequired,
  currentInterval: PropTypes.shape({
    start: PropTypes.string,
    end: PropTypes.string,
  }),
  defaultView: PropTypes.node,
  events: PropTypes.arrayOf(CalendarEventType),
  fetchEvents: PropTypes.func.isRequired,
  filterView: PropTypes.node,
  helpTextForEvent: PropTypes.func.isRequired,
  menuOptions: PropTypes.arrayOf(MenuOptionType),
  onDateChange: PropTypes.func,
  onEventClick: PropTypes.func,
  projectId: PropTypes.string.isRequired,
  selectedFilterOptions: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  tooltipData: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  tooltipTitle: PropTypes.func.isRequired,
};

CalendarCardContainer.defaultProps = {
  currentInterval: null,
  defaultView: null,
  events: null,
  filterView: null,
  menuOptions: [],
  onDateChange: null,
  onEventClick: null,
  selectedFilterOptions: [],
  tooltipData: null,
};

export const CalendarCardWithoutProvider = CalendarCardContainer;

// TODO: Fix this the next time the file is edited.
// eslint-disable-next-line import/no-default-export
export default withProvider(connect(mapStateToProps)(CalendarCardContainer));
